import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Map from "../components/map"
import ContactForm from "../components/contactForm"
import SEO from "../components/seo"
import News from "../components/news"
import StationLinks from "../components/stationLinks"

var newsLinkMap = []
newsLinkMap["Afleysingar"] = "/frettir_a"
newsLinkMap["Aðrir"] = "/frettir_o"
newsLinkMap["Leyfishafar"] = "/frettir_l"
newsLinkMap[undefined] = "/frettir"

var newsLinkMapEn = []
newsLinkMapEn["Afleysingar"] = "/news_a"
newsLinkMapEn["Aðrir"] = "/news_o"
newsLinkMapEn["Leyfishafar"] = "/news_l"
newsLinkMapEn[undefined] = "/news"

const IndexPage = () => (
  <Layout>
    <SEO title="Taxi.is | Forsíða" />
    <div className="front-page wow fadeIn" data-wow-delay="0.3s">
      <div className="row">
        <div className="fp-news col-md-5">
          <h2 className="title">Nýjustu fréttir</h2>
          <News />
        </div>
        <div className="fp-taxi col-md-7">
          <h2 className="title">Bifreiðastöðvar</h2>
          <StationLinks />
        </div>
      </div>

      <div className="contact row">
        <div className="map col-md-5">
          <Map />
        </div>
        <div className="contact-form col-md-7">
          <ContactForm />
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
